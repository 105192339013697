import Router from './routes/Router';
import './App.css';

function App() {
  return (
    <Router />
  );
}

export default App;
