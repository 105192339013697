import { React, lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loading from "../components/Loading";
import PageLoading from "../components/PageLoading";

const Login = lazy(() => import("../pages/Login/Login"));
const Foods = lazy(() => import("../pages/Foods/Foods"));
const Users = lazy(() => import("../pages/Users/Users"));
const Categories = lazy(() => import("../pages/Categories/Categories"));

const SideBarNavbar = lazy(() => import("./SidebarNavbar"));

function Router() {
  let routes = useRoutes([
    {
      element: (
        <Suspense fallback={<PageLoading />}>
          <Login />
        </Suspense>
      ),
      path: "/",
    },
    {
      element: (
        <Suspense fallback={<PageLoading />}>
          <SideBarNavbar />
        </Suspense>
      ),
      children: [
        {
          element: (
            <Suspense fallback={<Loading />}>
              <Foods />
            </Suspense>
          ),
          path: "/foods",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <Users />
            </Suspense>
          ),
          path: "/users",
        },
        {
          element: (
            <Suspense fallback={<Loading />}>
              <Categories />
            </Suspense>
          ),
          path: "/categories",
        },
      ],
    },
    {
      element: <Navigate to="/" />,
      path: "*",
    },
  ]);
  return routes;
}

export default Router;
